<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Turma
                </th>
              </tr>
            </thead>

            <tbody v-if="groups.length === 0 && !loading">
              <tr>
                <td colspan="3" class="px-6 py-8 whitespace-nowrap">
                  Não existem turmas cadastradas para você
                  <br />
                  Tente mudar o ano selecionado
                </td>
              </tr>
            </tbody>

            <tbody v-if="loading">
              <tr>
                <td colspan="3" class="px-6 py-8 whitespace-nowrap">
                  <svg
                    class="relative left-1/2 animate-spin -ml-1 mr-3 h-5 w-5 text-purple-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </td>
              </tr>
            </tbody>

            <tbody
              v-if="!loading && groups.length !== 0"
              class="bg-white divide-y divide-gray-200"
            >
              <tr v-for="group in groups" :key="group.id">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="text-md font-medium text-gray-900">
                      {{ group.turma }}<br />
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-md text-gray-500"></div>
                </td>
                <td
                  class="px-4 py-4 whitespace-nowrap text-right text-sm font-medium"
                >
                  <a
                    @click="navigate('chamada', group.id)"
                    class="cursor-pointer text-indigo-600 hover:text-indigo-900"
                    >Chamada</a
                  >
                </td>
                <td
                  class="px-4 py-4 whitespace-nowrap text-right text-sm font-medium"
                >
                  <a
                    @click="navigate('notas', group.id)"
                    class="cursor-pointer text-indigo-600 hover:text-indigo-900"
                    >Notas</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["groups", "loading"],
  mounted() {},
  methods: {
    navigate(to, groupId) {
      this.$router.push({ name: to, params: { groupId } });
    },
  },
};
</script>
