<template>
  <div
    class="
      w-100
      mx-auto
      bg-white
      border-t-4
      rounded
      border-indigo-500
      dark:bg-gray-800
      shadow-md
      font-sans
      text-center
      p-4
    "
  >
    <div class="overflow-hidden">
      <div class="flex flex-wrap item-center justify-between m-2 mb-6">
        <h1 class="text-2xl text-left font-medium text-gray-800">
          Minhas turmas
        </h1>
      </div>

      <div class="flex flex-wrap item-center justify-end m-2 mb-6">
        <input
          type="number"
          class="
            rounded-lg
            border-transparent
            appearance-none
            border border-gray-300
            py-2
            px-4
            bg-white
            text-gray-700
            placeholder-gray-400
            shadow-sm
            text-base
            focus:outline-none
            focus:ring-2
            focus:ring-purple-600
            focus:border-transparent
          "
          min="2020"
          step="1"
          v-model="selectedYearFilter"
        />
        <button
          @click="getMyGroups"
          class="
            px-3
            py-2
            bg-gray-800
            ml-2
            text-white text-xs
            font-bold
            uppercase
            rounded
            bg-indigo-600
            hover:bg-indigo-700
            focus:ring-indigo-500 focus:ring-offset-indigo-200
            transition
            ease-in
            duration-200
            font-semibold
            shadow-md
            focus:outline-none focus:ring-2 focus:ring-offset-2
          "
        >
          Filtrar por ano
        </button>
      </div>

      <MyGroupsTable :groups="groups" :loading="loadingGroups" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Firebase from "@/firebase";
import MyGroupsTable from "../components/MyGroupsTable.vue";

export default {
  components: { MyGroupsTable },
  setup() {
    const auth = Firebase.getAuth();
    const { uid } = auth.currentUser;
    const teacherRef = Firebase.doc(`${Firebase.collections.TEACHERS}/${uid}`);

    const currentYear = new Date().getFullYear();
    const selectedYearFilter = ref(currentYear);

    const loadingGroups = ref(false);
    const groups = ref([]);

    const getMyGroups = async () => {
      loadingGroups.value = true;

      const groupsSnapshots = await Firebase.getDocs(
        Firebase.collections.GROUPS,
        Firebase.where("ano", "==", selectedYearFilter.value),
        Firebase.where("professor", "==", teacherRef)
      );
      groups.value = groupsSnapshots.docs.map((snapshot) => ({
        ...snapshot.data(),
        id: snapshot.id,
      }));

      loadingGroups.value = false;
    };

    return {
      selectedYearFilter,
      getMyGroups,
      groups,
      loadingGroups,
    };
  },
  mounted() {
    this.getMyGroups();
  },
  methods: {},
};
</script>
